export default {
  data: () => ({
    totalpatientsPerPayer: null,
    patientsPerPayerseries: null,
    patientsPerPayerchartOptions: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        
        toolbar: {
          show: true,
        },
        // zoom: {
        //   enabled: true,
        // },
      },
      colors: ["#2C77FF", "#003696", "#E6F3FF"],
      states: {
        normal: {
            filter: {
                type: 'none',
                value: 0,
            }
        },
        hover: {
            filter: {
              type: 'none',
              value: 0,
            }
        },
 
    },
      plotOptions: {
        bar: {
          // borderRadius: 30,
          columnWidth: null,
        },
        
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      // plotOptions: {
      //   bar: {
      //     horizontal: false,
      //     borderRadius: 50,
      //   },
      // },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: null,
      },
      yaxis: {
        categories: null,
        labels: {
          formatter: function (val) {
            return val + "K";
          },
        },
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    },
  }),
};
