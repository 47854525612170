<template>
  <section class="reportProductsSidecard d-flex fill-height">
    <section class="contentSection scrollbar mx-3">
      <v-container fluid v-if="flagGetMainCharts">
        <v-row>
          <v-col md="4" cols="12">
            <v-select
              outlined
              v-model="selectedYear"
              :items="listOfYears"
              label="select Year"
              hide-details="auto"
              :menu-props="{ bottom: true, offsetY: true }"
              attach
              @change="changeReportYear()"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" cols="12">
            <div class="globalSectionHeader mb-4">Number Of Patient</div>
            <div class="conOfChart cardOfPatients">
              <v-card class="mx-auto" max-width="100%" tile elevation="1">
                <div class="headerSectionWhite pa-6">
                  <div class="secTitle">Total Number of Patient</div>
                  <div
                    class="
                      conDataPatient
                      d-flex
                      justify-space-between
                      align-center
                    "
                  >
                    <div class="secNumber">{{ totalNumberOfPatients }}</div>
                    <div class="conOfIncreaseVal">
                      <span>
                        {{ patients_per_year_percentage > 0 ? "+" : "-"
                        }}{{ patients_per_year_percentage }}%
                      </span>

                      <img
                        v-if="patients_per_year_percentage > 0"
                        src="@/assets/img/increaseLogo.png"
                        alt="logo"
                      />
                      <img
                        v-else
                        src="@/assets/img/decreaseLogo.png"
                        alt="logo"
                      />
                    </div>
                  </div>
                </div>
                <VueApexCharts
                  type="area"
                  :options="totalNumberPatientchartOptions"
                  :series="totalNumberPatientseries"
                  height="379"
                />
              </v-card>
            </div>
          </v-col>
          <v-col md="8" cols="12">
            <div class="globalSectionHeader mb-4">
              Number Of Patients Per Product
            </div>
            <div class="conOfChart">
              <v-card
                class="mx-auto pa-3"
                min-height="530px"
                max-width="100%"
                tile
                elevation="1"
              >
                <div class="headerSectionPrimary">
                  <div class="secNumber">
                    {{ totalNumberPatientsPerProduct }}
                  </div>
                  <div class="secTitle">Patient</div>
                </div>
                <VueApexCharts
                  type="bar"
                  :options="numberPatientsPerProductChartOptions"
                  :series="numberPatientsPerProductSeries"
                  height="350"
                />
              </v-card>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" cols="12">
            <div class="conOfChart cardTotalNumberPayers">
              <v-card class="mx-auto pa-3" max-width="100%" tile elevation="1">
                <div class="sectionContent d-flex justify-center align-center">
                  <div class="conIcon">
                    <v-icon> mdi-account-group </v-icon>
                  </div>
                  <div class="headerSectionWhite">
                    <div class="secTitle">Total Number of payer</div>
                    <div class="secNumber">{{ totalNumberofPayer }}</div>
                  </div>
                </div>
              </v-card>
            </div>
            <div class="conOfChart cardPatientAnalytics mt-4">
              <v-card class="mx-auto pa-3" max-width="100%" tile elevation="1">
                <div class="headerCard pa-4">Patient Analytics</div>
                <VueApexCharts
                  type="donut"
                  :options="patientAnalyticsChartOptions"
                  :series="patientAnalyticsSeries"
                />
              </v-card>
            </div>
          </v-col>
          <v-col md="8" cols="12">
            <div class="globalSectionHeader headerNumberPatientPerPayer">
              Number Of Patients Per Payer
            </div>
            <div class="conOfChart conOfChartNumberPatientPerPayer">
              <v-card
                class="fill-height mx-auto pa-3"
                max-width="100%"
                tile
                elevation="1"
              >
                <div class="headerSectionPrimary">
                  <div class="secNumber">{{ totalpatientsPerPayer }}</div>
                  <div class="secTitle">Patient</div>
                </div>
                <div class="conOfpatientsPerPayerChart">
                  <VueApexCharts
                    type="bar"
                    height="100%"
                    :options="patientsPerPayerchartOptions"
                    :series="patientsPerPayerseries"
                  />
                </div>
              </v-card>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="8" cols="12">
            <div class="globalSectionHeader headerPHIVsOther mb-4">
              PHI Vs Other Sectors
            </div>
            <div class="conOfChart conOfChartPHIVstherSectors">
              <v-card
                class="mx-auto pa-3"
                min-height="300"
                max-width="100%"
                tile
                elevation="1"
              >
                <v-row class="pt-5">
                  <v-col md="5" cols="12">
                    <VueApexCharts
                      type="donut"
                      :options="pHIVsOtherSectorsChartOptions"
                      :series="pHIVsOtherSectorsSeries"
                      height="280"
                    />
                  </v-col>
                  <v-col md="7" cols="12" class="d-flex align-center">
                    <v-row>
                      <v-col md="6" cols="12">
                        <div class="phiDataContainer">
                          <div class="phiHeader">
                            <div>
                              {{ phiVal }}
                            </div>
                            <div>
                              <span> PHI </span>
                            </div>
                          </div>
                          <div class="conOfIcons">
                            <v-icon
                              v-for="indexActive in pHINumberUsersOfThirty"
                              :key="indexActive"
                              class="activeIcon"
                            >
                              mdi-human-male
                            </v-icon>
                            <v-icon
                              v-for="indexInActive in 30 -
                              pHINumberUsersOfThirty"
                              :key="indexInActive + pHINumberUsersOfThirty"
                            >
                              mdi-human-male
                            </v-icon>
                          </div>
                        </div>
                      </v-col>
                      <v-col md="6" cols="12">
                        <div class="otherSectorsDataContainer">
                          <div class="otherSectorsHeader">
                            <div>
                              {{ otherSectorVal }}
                            </div>
                            <div>
                              <span> Other Sectors </span>
                            </div>
                          </div>
                          <div class="conOfIcons">
                            <v-icon
                              v-for="indexActive in otherSectorNumberUsersOfThirty"
                              :key="indexActive"
                              class="activeIcon"
                            >
                              mdi-human-male
                            </v-icon>
                            <v-icon
                              v-for="indexInActive in 30 -
                              otherSectorNumberUsersOfThirty"
                              :key="
                                indexInActive + otherSectorNumberUsersOfThirty
                              "
                            >
                              mdi-human-male
                            </v-icon>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </v-col>
          <v-col md="4" cols="12">
            <v-row>
              <v-col md="6" cols="12">
                <div class="globalSectionHeader mb-4">Product share</div>
              </v-col>
              <v-col md="6" cols="12">
                <v-select
                  outlined
                  v-model="productShareRes"
                  :items="productShareList"
                  item-text="name"
                  item-value="id"
                  label="Select Payer"
                  hide-details="auto"
                  :menu-props="{ bottom: true, offsetY: true }"
                  attach
                  @change="changeProductShare()"
                  :disabled="!productShareList.length"
                ></v-select>
              </v-col>
            </v-row>
            <div class="conOfChart mt-4">
              <v-card
                class="mx-auto pa-3 pt-5"
                min-height="300"
                height="100%"
                max-width="100%"
                tile
                elevation="1"
              >
                <section v-if="!productShareFlag" class="conOfShareProd">
                  <div v-if="productShareTotalPatient">
                    <VueApexCharts
                      type="donut"
                      :options="productshareChartOptions"
                      :series="productshareChartSeries"
                      height="280"
                    />
                  </div>
                  <div class="shreProductsNoPatient" v-else>
                    NO PATIENTS EXIST
                  </div>
                </section>
                <div class="loaderContainer" v-else>
                  <v-progress-circular
                    :size="100"
                    :width="4"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-card>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <div class="globalSectionHeader">Total Patients Per City</div>
          </v-col>
        </v-row>
      </v-container>

      <div class="loaderContainer" v-else>
        <v-progress-circular
          :size="120"
          :width="4"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <v-container fluid v-show="flagGetMainCharts">
        <v-row>
          <v-col cols="12">
            <div class="conOfChart">
              <v-card
                class="mx-auto pa-0"
                min-height="300"
                max-width="100%"
                tile
                elevation="1"
              >
                <div
                  class="
                    allSecContent
                    d-flex
                    align-center
                    justify-space-between
                  "
                >
                  <div
                    class="conOfChart conOfCityChart"
                    v-if="flagGetMainCharts"
                  >
                    <VueApexCharts
                      type="bar"
                      :options="patientsPerCityChartOptions"
                      :series="patientsPerCitySeries"
                      height="350"
                    />
                  </div>
                  <div
                    class="conOfMapSection"
                    style="width: 100%; height: 400px"
                  >
                    <GmapMap
                      :center="center"
                      :options="mapStyle"
                      :zoom="zoom"
                      ref="map"
                      style="width: 100%; height: 400px"
                    >
                      <gmap-info-window
                        :options="infoOptions"
                        :position="infoPosition"
                        :opened="infoOpened"
                        @closeclick="infoOpened = false"
                      >
                        <div class="styleofWindow pa-2">
                          <div class="cityName">
                            {{ infoContent }}
                          </div>
                          <div class="infoTotalNum">
                            <span>total: </span>
                            <span>{{ infoTotalNum }}</span>
                          </div>
                          <div class="infoPercentage">
                            <span>Percentage: </span>
                            <span>{{ infoPercentage }}%</span>
                          </div>
                        </div>
                      </gmap-info-window>
                      <GmapCircle
                        v-for="(pin, index) in markers"
                        :key="index"
                        :center="JSON.parse(pin.position)"
                        :radius="30000"
                        :visible="true"
                        :options="{
                          fillColor: pin.color,
                          fillOpacity: 0.8,
                          strokeColor: pin.color,
                          strokeOpacity: 0.8,
                        }"
                        :clickable="true"
                        @mouseover="toggleInfo(pin, index)"
                        @mouseout="infoOpened = false"
                      ></GmapCircle>
                    </GmapMap>
                  </div>
                </div>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- <EmptyState v-else></EmptyState> -->
    <SidebarProducts />
  </section>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();

import VueApexCharts from "vue-apexcharts";
import SidebarProducts from "../sideBarProducts/index.vue";

import numberPatientsPerProduct from "../../mixins/reportAllProductsChart/numberPatientsPerProduct";
import patientAnalytics from "../../mixins/reportAllProductsChart/patientAnalytics";
import patientsPerCity from "../../mixins/reportAllProductsChart/patientsPerCity";
import patientsPerPayer from "../../mixins/reportAllProductsChart/patientsPerPayer";
import pHIVsOtherSectors from "../../mixins/reportAllProductsChart/pHIVsOtherSectors";
import productshareChart from "../../mixins/reportAllProductsChart/productshareChart";
import totalNumberPatient from "../../mixins/reportAllProductsChart/totalNumberPatient";
import MapCitiesAllProduct from "../../mixins/mapCitiesAllProduct";
export default {
  mixins: [
    numberPatientsPerProduct,
    patientAnalytics,
    patientsPerCity,
    patientsPerPayer,
    pHIVsOtherSectors,
    productshareChart,
    totalNumberPatient,
    MapCitiesAllProduct,
  ],
  data: () => ({
    progressVal1: 30,
    progressVal2: 45,
    totalNumberofPayer: null,
    productShareRes: null,
    flagGetMainCharts: false,
    productShareFlag: false,
    productShareList: [],
    listOfYears: [],
    selectedYear: null,
    pHINumberUsersOfThirty: null,
    otherSectorNumberUsersOfThirty: null,
  }),

  components: {
    VueApexCharts,
    SidebarProducts,
  },
  methods: {
    checkArrayLength(len, propName, colName) {
      switch (true) {
        case len == 1:
          propName.plotOptions.bar[colName] = "8%";
          break;
        case len == 2:
          propName.plotOptions.bar[colName] = "15%";
          break;
        case len == 3:
          propName.plotOptions.bar[colName] = "22%";
          break;
        case len > 3:
          propName.plotOptions.bar[colName] = "25%";
          break;
        case len > 6 && len < 10:
          propName.plotOptions.bar[colName] = "35%";
          break;
        default:
          propName.plotOptions.bar[colName] = "50%";
      }
    },
    twoItemsToArray(array, filterEl1, filterEl2) {
      let array1 = [];
      let array2 = [];
      array.forEach((el) => {
        array1.push(el[filterEl1]);
        array2.push(el[filterEl2]);
      });
      return { array1, array2 };
    },
    numberOfPatients(numberOfPatient) {
      this.totalNumberOfPatients = numberOfPatient.total_number_of_patients;
      this.patients_per_year_percentage = parseInt(
        numberOfPatient.patients_per_year_percentage
      );
      let result = this.twoItemsToArray(
        numberOfPatient.patients_per_year,
        "year",
        "total"
      );
      this.totalNumberPatientseries[0].data = result.array2;
      this.totalNumberPatientchartOptions.xaxis.categories = result.array1;
    },
    numberOfPatientsPerProduct(numberOfPatient) {
      this.totalNumberPatientsPerProduct = numberOfPatient.total_patients;
      let result = this.twoItemsToArray(numberOfPatient.data, "name", "total");
      this.checkArrayLength(
        result.array1.length,
        this.numberPatientsPerProductChartOptions,
        "columnWidth"
      );

      this.numberPatientsPerProductSeries[0].data = result.array2;
      this.numberPatientsPerProductChartOptions.xaxis.categories =
        result.array1;
      this.numberPatientsPerProductChartOptions.yaxis.categories =
        result.array1;
    },
    patientAnalytics(patients) {
      this.patientAnalyticsSeries = [];
      this.patientAnalyticsSeries.push(
        patients.approved,
        patients.rejected,
        patients.pending
      );
      let total = patients.approved + patients.rejected + patients.pending;
      this.patientAnalyticsChartOptions.plotOptions.pie.donut.labels.value.formatter =
        (value) => ((value / total) * 100).toFixed(2) + "%";
    },
    patientsPerPayer(patients) {
      let approvedArray = [];
      let rejectedArray = [];
      let pendingArray = [];
      let categories = [];
      patients.data.forEach((el) => {
        approvedArray.push(el["approved"]);
        rejectedArray.push(el["rejected"]);
        pendingArray.push(el["pending"]);
        categories.push(el["name"]);
      });
      this.patientsPerPayerseries = [
        {
          name: "Approved",
          data: approvedArray,
        },
        {
          name: "Reject",
          data: rejectedArray,
        },
        {
          name: "Pending",
          data: pendingArray,
        },
      ];
      this.checkArrayLength(
        categories.length,
        this.patientsPerPayerchartOptions,
        "columnWidth"
      );
      this.patientsPerPayerchartOptions.xaxis.categories = categories;
      this.totalpatientsPerPayer = patients.total_patients;
    },
    pHIVsOtherSectors(product) {
      this.phiVal = product.phi;
      this.otherSectorVal = product.other_sectors;
      let totalVal = product.phi + product.other_sectors;
      this.pHIVsOtherSectorsSeries = [];
      this.pHIVsOtherSectorsSeries.push(product.phi, product.other_sectors);
      this.pHIVsOtherSectorsChartOptions.plotOptions.pie.donut.labels.value.formatter =
        (value) => ((value / totalVal) * 100).toFixed(2) + "%";

      if (product.phi > 0) {
        let PHIpreventageVal = (product.phi / totalVal) * 100;
        this.pHINumberUsersOfThirty = Math.round((PHIpreventageVal * 30) / 100);
      }

      if (product.other_sectors > 0) {
        let otherSectorValpreventageVal =
          (product.other_sectors / totalVal) * 100;
        this.otherSectorNumberUsersOfThirty = Math.round(
          (otherSectorValpreventageVal * 30) / 100
        );
      }
    },
    patientsPerCity(patients) {
      // this.totalNumberOfPatients = numberOfPatient.total_number_of_patients;
      this.totalNumCitiesPatients = patients.total_patients;
      let result = this.twoItemsToArray(patients.data, "name", "population");
      this.patientsPerCitySeries[0].data = result.array2;

      this.checkArrayLength(
        result.array1.length,
        this.patientsPerCityChartOptions,
        "barHeight"
      );

      this.patientsPerCityChartOptions.xaxis.categories = result.array1;
      this.patientsPerCityChartOptions.yaxis.categories = result.array1;

      if(patients.total_patients > 0) {
      this.patientsPerCityChartOptions.dataLabels.formatter = (value) =>
        ((value / patients.total_patients) * 100).toFixed(2) + "%";
      } else {
              this.patientsPerCityChartOptions.dataLabels.formatter = () => "0%";
      }
    },
    getYearsFilterValidation() {
      let currentYear = new Date().getFullYear();
      this.listOfYears.push(currentYear, currentYear - 1, currentYear - 2);
      this.selectedYear = currentYear;
    },
    productshare(product) {
      let name = [];
      let amount = [];
      let percentage = [];
      let total = [];
      product.forEach((el) => {
        name.push(el["name"]);
        amount.push(parseInt(el["amount"]));
        percentage.push(el["percentage"]);
        total.push(parseInt(el["total"]));
      });
      this.productshareChartSeries = amount;
      this.productshareChartOptions.labels = name;
      this.productshareChartOptions.plotOptions.pie.donut.labels.value.formatter =
        (value) => ((value / total[0]) * 100).toFixed(2) + "%";
    },
    productShareRequest(payerId, year) {
      this.productShareFlag = true;
      apiServices
        .get(`admin/product-share-payer/${payerId}?year=${year}`)
        .then((res) => {
          if (res) {
            this.productshare(res.product_share.data);
            this.productShareTotalPatient = res.product_share.total_patients;
            this.productShareFlag = false;
          }
        });
    },
    initialize(year) {
      // this.productsList = null;
      this.flagGetMainCharts = false;

      apiServices.get(`admin/main-charts?year=${year}`).then((res) => {
        if (res) {
          // this.productsList = res;
          this.numberOfPatients(res.number_of_patients);
          this.numberOfPatientsPerProduct(res.patient_per_products);
          this.patientAnalytics(res.patient_analytics);
          this.patientsPerPayer(res.patient_per_payers);
          this.pHIVsOtherSectors(res.phi_vs_sectors);
          this.patientsPerCity(res.patient_per_cities);
          this.totalNumberofPayer = res.total_no_payers.total;
          this.getMapData(res.patient_per_cities);
          setTimeout(() => {
            this.flagGetMainCharts = true;
          }, 500);
        }
      });
    },
    getPayers(year) {
      this.productShareList = [];
      apiServices.get("admin/payers").then((res) => {
        if (res) {
          this.productShareList = res.data;
          this.productShareRes = res.data[0].id;
          this.productShareRequest(this.productShareRes, year);
        }
      });
    },
    changeProductShare() {
      this.productShareRequest(this.productShareRes, this.selectedYear);
    },
    changeReportYear() {
      this.initialize(this.selectedYear);
      this.getPayers(this.selectedYear);
    },
  },
  created() {
    this.getYearsFilterValidation();
    this.initialize(this.selectedYear);
    this.getPayers(this.selectedYear);
  },
};
</script>
<style lang="scss" scoped>
@import "./_allProducts.scss";
.wrapper {
  max-width: 400px;
  margin: 0 auto;
}
</style>
