export default {
  data: () => ({
    phiVal: null,
    otherSectorVal: null,
    pHIVsOtherSectorsSeries: [],
    pHIVsOtherSectorsChartOptions: {
      chart: {
        type: "donut",
      },
      legend: {
        show: false,
      },
      labels: ["PHI", "Other Sectors"],
      colors: ["#26BBE3", "#935CCB"],
      plotOptions: {
        pie: {
          donut: {
            size: "80%",
            labels: {
              show: true,
              name: {
                show: true,
                fontWeight: 600,
                fontSize: "22px",
                color: "#63799B",
              },
              value: {
                show: true,
                fontWeight: 600,
                fontSize: "22px",
                color: "#63799B",
              },
              total: {
                show: true,
                color: "#63799B",
                fontWeight: 600,
                fontSize: "22px",
                label: "Total",
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 270,
            },
            legend: {
              show: false,
              position: "bottom",
              // offsetY: 0,
            },
          },
        },
      ],
    },
  }),
};
