export default {
  data: () => ({
    patientAnalyticsSeries: [],
    returnData: 100,
    patientAnalyticsChartOptions: {
      chart: {
        type: "donut",
      },
      legend: {
        position: "right",
        fontSize: "16px",
        // offsetY: 50,
        labels: {
          colors: "#fffc",
        },
        formatter: function (label, opts) {
          return (
            label +
            " <br/> <span style='font-weight: bold;font-size: 23px;color: #fff;text-align: center;display: block;'>" +
            opts.w.globals.series[opts.seriesIndex] +
            "</span> "
          );
        },
      },
      labels: ["Approved", "Rejected", "pending"],
      colors: ["#43E0AA", "#935CCB", "#2C77FF"],
      stroke: {
        show: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "80%",
            background: "#fff",

            labels: {
              show: true,
              name: {
                show: true,
                fontWeight: 600,
                fontSize: '22px',
                color: "#63799B",

              },
              value: {
                show: true,
                fontWeight: 600,
                fontSize: '22px',
                color: "#63799B",
              },
              total: {
                show: true,
                color: "#63799B",
                fontWeight: 600,
                fontSize: '22px',
                label: "Total",
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              show: true,
              position: "bottom",
              offsetY: 0,
            },
          },
        },
      ],
    },
  }),
};
