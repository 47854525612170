export default {
  data: () => ({
    productShareTotalPatient: null,
    productshareChartSeries: null,
    productshareChartOptions: {
      chart: {
        type: "donut",
      },
      legend: {
        position: "right",
        fontSize: "16px",
      },
      labels: null,
      colors: [
        "#053696",
        "#576F9B",
        "#2C77FC",
        "#D5D5D6",
        "#2FA0DD",
        "#7C76B9",
      ],
      plotOptions: {
        pie: {
          donut: {
            size: "80%",
            labels: {
              show: true,
              name: {
                show: true,
                fontWeight: 600,
                fontSize: '22px',
                color: "#63799B",

              },
              value: {
                show: true,
                fontWeight: 600,
                fontSize: '22px',
                color: "#63799B",
              },
              total: {
                show: true,
                color: "#63799B",
                fontWeight: 600,
                fontSize: '22px',
                label: "Total",
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              show: false,
              position: "bottom",
              // offsetY: 0,
            },
          },
        },
      ],
    },
  }),
};
