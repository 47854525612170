export default {
  data: () => ({
    patients_per_year_percentage: null,
    totalNumberPatientseries: [
      {
        name: "Number of Patients",
        data: null,
      },
    ],
    totalNumberPatientchartOptions: {
      colors: ["#fff"],
      fill: {
        colors: ["#0059FA"],
          opacity: 1,
          gradient: {
            shade: 'dark',
            type: "vertical",
            shadeIntensity: 0.5,
            // gradientToColors: ["#0059FA", "#0059fa"],
            inverseColors: true,
            opacityFrom: .3,
            opacityTo: .6,
            stops: [0, 100],
            colorStops: [ ]
        },
      },
      yaxis: {
        show: false,
        labels: {
          show: false,
        },
        lines: {
          show: false,
        },
      },
      
      xaxis: {
        show: false,
        categories: null,
        lines: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      grid: {
        show: false,
        padding: {
          left: 50,
          right: 50,
          bottom: 0
        }
      },
      chart: {
        type: "area",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
      }
      },
      dataLabels: {
        enabled: true,
        formatter: function(value, opt) {
          return `${value} / Y${opt.w.globals.categoryLabels[opt.dataPointIndex]} `
        },
        offsetY: -15,
        // offsetX: -15,
        style: {
          fontSize: "15px",
          colors: ["#ffffff"],
        },
        background: {
          enabled: false,
        },
        dropShadow: {
          enabled: false,

      }
      },
      stroke: {
        curve: "smooth",
      },
      tooltip: {
        show: true,
      },
      markers: {
        colors: "#0059FA",
        strokeColors: "#fff",
        size: 8,
        hover: {
          size: 12,
        },
      },
    },
  }),
};
