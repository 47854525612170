export default {
  data: () => ({
    patientsPerCitySeries: [
      {
        name: 'patients Per City',
        data: null,
      },
    ],
    patientsPerCityChartOptions: {
      chart: {
        type: "bar",
        toolbar: {
          show: true,
        },
      },
      colors: ["#FD79A8"],

      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          barHeight: null,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: 25,
        formatter: null,
        style: {
          fontSize: "12px",
          colors: ["#554B86"],
        },
      },
      grid: {
        show: false,
        padding: {
          right: 20,
        }
      },
      xaxis: {
        categories: null,
        labels: {
          formatter: function (val) {
            return val + "K";
          },
        },
      },
      yaxis: {
        categories: null,
      },
    },
  }),
};
