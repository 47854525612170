export default {
  data: () => ({
    totalNumberPatientsPerProduct: null,
    numberPatientsPerProductSeries: [
      {
        name: "Number of Patients Per Product",
        data: null,
      },
    ],
    numberPatientsPerProductChartOptions: {
      chart: {
        type: "bar",
        toolbar: {
          show: true,
        },
      },
      colors: ["#935CCB"],

      plotOptions: {
        bar: {
          borderRadius: 4,
          columnWidth: null,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: null,
      },
      yaxis: {
        categories: null,
        labels: {
          formatter: function (val) {
            return val + "K";
          },
        },
      },
    },

  }),
};
